<template>
  <div>
    <!-- form表单 -->
    <el-form ref="nextForm" :model="nextForm" label-width="90px" style="display: flex;justify-content: space-between;">
      <div>
        <el-row type="flex" justify="space-between">
          <div>
            <el-row class="Row-Input">
              <el-col :span="8">
                <el-form-item :label="$t('title.platforms')">
                  <el-select v-model="nextForm.site" class="addWidth" value-key="id" clearable filterable @change="PlatformsiteChange">
                    <el-option :label="$t('title.All')" value="">{{ $t('title.All') }}</el-option>
                    <el-option
                      v-for="item in terraceDatas"
                      :key="item.id"
                      :label="item.siteCode"
                      :value="item.siteCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('title.SellingAccount')">
                  <el-select v-model="nextForm.account" class="addWidth" value-key="id" collapse-tags multiple clearable filterable @change="PlatformAccountChange">
                    <el-option :label="$t('title.All')" value="">{{ $t('title.All') }}</el-option>
                    <el-option
                      v-for="item in PlatformAccountList"
                      :key="item.id"
                      :label="item.account"
                      :value="item.account"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <!-- BU -->
              <el-col :span="8">
                <el-form-item label="BU">
                  <el-select v-model="nextForm.bu" class="addWidth" value-key="id" collapse-tags multiple filterable>
                    <el-option
                      v-for="item in buOptions"
                      :key="item.id"
                      :label="item.bu"
                      :value="item.bu"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

            </el-row>
          </div>
        </el-row>
      </div>
      <el-col :span="4">
        <el-button class="ml-3" type="primary" :loading="sendLoading" size="mini" @click="closeOrder()">{{ $t('title.Closepage') }}</el-button>
        <el-button class="ml-3" type="primary" size="mini" @click="insertShopify()">{{ $t('title.Blockedmail') }}</el-button>
      </el-col>
    </el-form>
    <!-- 最新消息弹出框 -->
    <div style="display: flex; justify-content: space-between">
      <span>
        <span class="ml-3">{{ $t("title.platform") }}：{{ basicInfo.platform }}</span>
        <span class="ml-3">{{ $t("title.number") }}：{{ basicInfo.account }}</span>
        <span class="ml-3">{{ $t("title.Customerids") }}：{{ basicInfo.messageSender }}</span>
        <!-- <p>主题: {{basicInfo.ebayMessageInfoList[0].subject}}</p> -->
      </span>
      <span>
        <el-button v-if=" basicInfo.messageStatus === 1 || basicInfo.messageStatus === 2 " type="primary" size="small" :disabled=" basicInfo.messageStatus === 1 || basicInfo.messageStatus === 2 ">{{ $t("title.replied") }}</el-button>
        <!-- <el-button type="primary" size="small" @click="handlenextMessage(0)">上一封</el-button> -->

      </span>
    </div>
    <p> {{ $t("title.Subjectmail") }}:{{ basicInfo.messageText }} </p>
    <el-row type="flex" justify="space-between">
      <!-- 聊天窗口 -->
      <!-- <div style="width: 20%">
          <span>消息预览</span>
          <div style="height:550px;overflow-y: scroll;">
            <ul style="list-style: none;padding:0;margin:0;border-bottom: 1px solid #e1e1e1;border-left: 1px solid #e1e1e1;border-right: 1px solid #e1e1e1;">
              <li v-for="(items, index) in historyData" :key="index" style="border-top: 1px solid #e1e1e1;padding:8px;cursor:pointer;" :class="{ active: index === changeIndex }" @click="handleHistoryData(items.id,index)">
                <p style="display: flex; justify-content: space-between;">
                  <span>{{ items.messageSender }}</span>
                  <span>{{ items.createTime }}</span>
                </p>
                <p>{{ items.messageText }}</p>
              </li>
            </ul>
          </div>
        </div> -->
      <el-row style="width: 70%">
        <el-row style="border-bottom: 1px solid #ccc">{{
          basicInfo.subject
        }}</el-row>
        <div class="wrap mt-3" :style="faceShow ? 'min-height: 606px' : 'min-height:400px'" style="position: relative;">
          <!-- 中间 聊天内容区域 -->
          <div ref="singleHeight" class="main">
            <ul v-for="(item, index) in customerMessageList" ref="ul" :key="index" class="talk_list">

              <li>
                <div v-if="item.type === 1&&!disabledFlag" :class="{'left_word':!disabledFlag,'Left_Word':disabledFlag}">
                  <p style="padding-left:10px"><span style="color:#000">{{ basicInfo.messageSender }}</span><span style="display:inline-block;padding-left:10px">{{ item.createdTime }}</span> </p>
                  <div v-if="item.body" style="white-space: pre-wrap;"> {{ item.body }}<br>
                    <span v-for="(ITEM, index) in item.mediaList" :key="index">

                      <el-image v-if="ITEM.mediaType.includes('image')" :src="ITEM.mediaURL" :preview-src-list="getSrcList(index, item.mediaList)" />
                      <el-link v-else type="primary" :href="ITEM.mediaURL" target="_blank"> {{ ITEM.mediaURL }}</el-link>
                    </span>
                  </div>
                </div>
                <div v-else-if="item.type === 1 && disabledFlag" :class="{ left_word: !disabledFlag, Left_Word: disabledFlag, }">
                  <div v-if="item.body" v-html="item.body" />
                </div>
                <div v-else>
                  <p v-if="item.messageStatus !== 3" style="text-align:right;padding-right:10px;"><span style="color:#666">{{ item.operatorName }}</span><span style="display:inline-block;padding-left:10px">{{ item.createdTime }}</span> </p>
                  <div v-if="item.messageStatus !== 3" class="right_word">
                    <span v-if="item.body" v-html="item.body" />
                    <span v-if="item.mediaList"><img
                      v-for="(ITEM, index) in item.mediaList"
                      :key="index"
                      :src="ITEM.mediaURL"
                      style="width:50px;height:50px;"
                    ></span>
                  </div>
                </div>
              </li>
            </ul>
            <p style="text-algin:center;position:absolute;top: 335px;left:50%;transform: translateX(-50%);z-index: 99;cursor:pointer;color:	#6495ed;margin: 0;" @click="allMeassge">{{ $t("title.Allmessages") }}</p>
          </div>
          <!-- 底部 消息编辑区域 -->
          <div>
            <el-input id="textarea" ref="input" v-model="content" autofocus="autofocus" :rows="6" type="textarea" :placeholder="$t('title.EnterSend')" @keydown.enter.native="keyDown" />
            <div class="footer"> <el-checkbox v-model="checked" class="mr-3">{{ $t("title.Autonextmessage") }}</el-checkbox>
              <i class="el-icon-s-custom ml-3" @click="faceContent" />
              <i><el-upload
                ref="upload"
                style="display: inline-block;"
                :headers="uploadHeaders"
                :action="action"
                :before-upload="beforeAvatarUpload"
                :on-success="handleSuccess"
              >
                <i class="el-icon-picture-outline ml-3" />
              </el-upload></i>
              <el-dropdown @command="nikeName">
                <span class="el-dropdown-link ml-3">
                  {{ $t("title.Insert") }}<i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="客户昵称">{{ $t("title.Customerid") }}</el-dropdown-item>
                  <el-dropdown-item command="账号名称">{{ $t("title.Account") }}</el-dropdown-item>
                  <el-dropdown-item command="客服名称">{{ $t("title.Agentname") }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <span class="ml-3" style="cursor:pointer;" @click="nikeName">插入客户昵称</span> -->
              <el-button class="ml-3" type="primary" :loading="sendLoading" size="mini" @click="allsendMsg">  {{ $t("title.Closeworkorder") }}</el-button>
              <el-button class="ml-3" type="primary" :loading="sendLoading" size="mini" @click="sendMsg(1)">
                {{ $t("title.send") }}</el-button>
              <el-button type="primary" size="small" @click="handlenextMessage(1)">{{ $t("title.nextMessage") }}</el-button>
              <!-- 表情区域 -->
              <div v-if="faceShow" class="browBox">
                <ul>
                  <li v-for="(item, index) in faceList" :key="index" @click="getBrow(index)">
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-left:15px;margin-top:10px;font-size:16px">{{ $t("title.Template") }}</div>
        <div style="display: flex;justify-content:space-between;">
          <i class="el-icon-refresh" style="cursor:pointer;margin-top: 16px;padding-right:10px;" @click="_getTemplatePersonByUserId()" />
          <el-tabs v-model="templateType" style="width: 70%;" @tab-click="handleClick">
            <el-tab-pane v-for="(item,index) in templateData" :key="index" :label="item.templateType">
              <div style="display: flex;justify-content:space-between;">
                <div style="width:50%">
                  <p>{{ $t("title.Producttitle") }}</p>
                  <div v-for="(items,ind) in templateData[index].templateList" :key="ind" style="margin: 15px 0;padding:5px 0;line-height: 26px;height: 52px;">{{ items.templateTitle }}</div>
                </div>
                <div style="width:50%">
                  <p>{{ $t("title.Preview") }}</p>
                  <div v-for="(items,ind) in templateData[index].templateList" :key="ind" :title="items.templateContent" style="margin: 15px 0;padding:5px 0;cursor:pointer;height: 53px;line-height: 26px;" @click="handleContent(items.templateContent)">{{ items.templateContent | ellipsis }}</div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
          <el-row style="width: 30%;margin-left:5px">
            <p><el-input v-model="inputContent" placeholder="请输入内容" clearable @input="getContent" /></p>
            <div v-for="(i,index) in contentData" :key="index">
              <p style="cursor:pointer;" @click="handleContent(i.templateContent)">{{ i.templateContent }}</p>
            </div>
          </el-row>
        </div>

      </el-row>
      <el-row class="ml-3" style="width: 30%">
        <OrderInfo
          ref="orderinfo"
          :basic-info="basicInfo"
          :order-info="OrderInfo"
          :note-lists="noteLists"
          :dialog-visible="dialogVisible"
          @getfocus="getfocus"
        />
      </el-row>
    </el-row>
  </div>
</template>

<script>
import OrderInfo from '../Shopify-buyer-email/components/OrderInfo'
import {
  getMessageInfoByCondition,
  getTemplateContent,
  getTemplatePersonByUserId,
  getAmazonPagedList,
  batchUpdateMessageWorkStatus,
  getAllLogByMessageId,
  batchAmazonUpdateMessageStatus,
  sendmail,
  batchUpdateMessageWarnById,
  updateAmazonMessageReadById,
  getLogByMessageId,
  mailaccountinfo,
  querySiteList,
  allBU,
  updateMessageServiceIsWorkingById,
  insertShopifyShieldEmail
} from '@/api/service-management'
import { deepClone } from '@/utils'
// import store from '@/store'
import mixin from '@/mixin/oms-mixin'
// import Editor from '@/components/quil-editor'
const appData = require('@/views/service-manage/emoji.json')
export default {
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 38) {
        // 判断字段
        return value.slice(0, 38) + '...'
      }
      return value
    }
  },
  components: {
    OrderInfo
  },
  mixins: [mixin],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    disabledFlag: {
      type: Boolean,
      default: false
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    tableHeight: {
      type: String,
      default: ''
    },
    tableData: {
      type: Array,
      default: () => []
    },
    pager: {
      type: Object,
      default: () => ({})
    },
    terraceData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      templateType: 0,
      sellerFileList: [],
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      action: process.env.VUE_APP_UPLOAD_API,
      radio1: 0,
      sendLoading: false,
      OrderInfo: [],
      dialogVisible: false,
      basicInfo: {},
      multipleSelection: [],
      ids: [],
      threadListMarkasreplied: [],
      checked: false,
      content: '',
      customerMessageList: [],
      faceList: [],
      faceShow: false,
      getBrowString: '',
      imgs: [],
      noteLists: [],
      historyData: [],
      templateData: [],
      contentData: [],
      inputContent: '',
      temp: null, // 倒计时初始
      changeIndex: ' ',
      defaultText: '',
      // 富文本更新的值
      richText: '',
      nextForm: {
        account: '',
        site: '',
        bu: ''
      },
      buOptions: [],
      terraceDatas: [],
      PlatformAccountList: [],
      workName: ''
      // tableData: []
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.faceShow = false
        this.checked = false
        this.$refs.orderinfo.noteList = []
        this.basicInfo.messageMarkLabelList &&
        this.basicInfo.messageMarkLabelList.length
          ? (this.basicInfo.messageMarkLabelList[0].markLabel = '')
          : ''
        this.OrderInfo ? (this.OrderInfo = []) : ''
      }
    }
  },
  created() {
    // 等待组件加载完毕赋值
    this.$nextTick(() => {
      this.defaultText = `<p></p>`
    })
    // window.addEventListener('beforeunload', e => this.updateHandler(e))
  },
  mounted() {
    this._getTemplatePersonByUserId()
    this.timer()
    this._getAmazonPagedList()
    this._allBU()
    this._querySiteList()
    this.getmailaccountinfo('SHOPIFY')
    window.addEventListener('onunload', e => {
      this.updateHandler(e)
    })
  },
  destroyed() {
    // 切记页面销毁需要销毁
    clearInterval(this.temp)
    // window.removeEventListener('beforeunload', e => this.updateHandler(e))
    window.removeEventListener('onunload', e => {
      this.updateHandler(e)
    })
  },
  methods: {
    updateHandler(e) {
      this._updateMessageServiceIsWorkingById({ id: this.basicInfo.id, isWorking: 0 })
    },
    async _allBU() {
      const { datas } = await allBU()
      // this.buOptions = datas
      this.buOptions = []
      datas.map(e => {
        const index = this.buOptions.findIndex((value) => value.bu === e.bu)
        index === -1 ? this.buOptions.push(e) : ''
      })
    },
    // 屏蔽邮箱
    insertShopify() {
      this.$confirm('屏蔽后,将无法收到此邮箱发送过来的邮件,是否确认操作!', '屏蔽邮箱', {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel')
      }).then(() => {
        const { ebayMessageInfoList, messageSender, account } = this.basicInfo
        const data = Object.assign({}, { account, emailAddress: ebayMessageInfoList[0].senderId, name: messageSender })
        this._insertShopifyShieldEmail(data)
      })
    },
    async _insertShopifyShieldEmail(row) {
      const { msg } = await insertShopifyShieldEmail(row)
      this.$notify({
        title: msg,
        message: msg,
        type: 'success'
      })
    },

    // 平台站点
    async _querySiteList() {
      const { datas } = await querySiteList({ sellPlatformId: 8 })
      this.terraceDatas = datas
    },
    async getmailaccountinfo(platform) {
      const { data } = await mailaccountinfo(platform)
      this.PlatformAccountList = data
    },
    PlatformsiteChange(val) {
      this.nextForm.site = val
    },
    PlatformAccountChange(val) {
      this.nextForm.account = val
    },
    // 处理倒计时
    timer() {
      // 页面多个定时器 //主要逻辑都在这页面更新
      this.temp = setInterval(() => {
        this.tableData.forEach((item, index) => {
          // let startTime = new Date(item.createTime).getTime();
          const endTime =
            new Date(item.createTime).getTime() + 24 * 60 * 60 * 1000
          item.endTime = this.countDownFun(endTime)
          this.$set(this.tableData, item.endTime, this.countDownFun(endTime))
          // this.$set(this.tableData,"endTime", item.endTime)
        })
      }, 1000)
    },
    // 倒计时
    countDownFun(time) {
      time-- // 时间一秒秒的减
      const nowTime = new Date().getTime() // 现在时间
      if (nowTime <= time) {
        // 获取时间差
        const timediff = Math.round((time - nowTime) / 1000)
        // 获取还剩多少天
        const day = parseInt(timediff / 3600 / 24)
        // 获取还剩多少小时
        const hour = parseInt((timediff / 3600) % 24)
        // 获取还剩多少分钟
        const minute = parseInt((timediff / 60) % 60)
        // 获取还剩多少秒
        const second = timediff % 60
        // return day + "天" + hour + "小时" + minute + "分" + second + "秒";
        // return second+"s"
        if (day !== 0) {
          return day + 'day'
        } else if (hour !== 0) {
          return hour + 'h'
        } else if (minute !== 0) {
          return minute + 'min'
        } else if (second !== 0) {
          return second + 's'
        }
      } else {
        // return "00天00小时00分00秒";
        return '超'
      }
    },
    // 获取所有使用模板
    async _getTemplatePersonByUserId() {
      const { datas } = await getTemplatePersonByUserId({
        userId: this.$store.getters.userId,
        platform: 'SHOPIFY'
      })
      this.templateData = datas
      console.log(this.templateData)
    },
    async getContent() {
      const { datas } = await getTemplateContent({
        userId: this.$store.getters.userId,
        platform: 'SHOPIFY',
        content: this.inputContent
      })
      this.contentData = datas
    },
    getSrcList(index, srcList) {
      let List = []
      List = srcList.map((item) => item.mediaURL)
      return List.slice(index).concat(List.slice(0, index))
    },
    async closeDialog() {
      const { account, id, messageId, ebayMessageInfoList } = this.basicInfo
      if (
        this.content.match(/^[ ]*$/) &&
        this.content.replace(/(^s*)|(s*$)/g, '').length > 0
      ) {
        this.content = ''
      }
      const params = Object.assign(
        {},
        {
          account,
          serviceMessageId: id,
          messageId,
          messageStatus: 3,
          senderId: ebayMessageInfoList[0].recipientId,
          senderType: 2,
          subject: ebayMessageInfoList[0].subject,
          recipientId: ebayMessageInfoList[0].senderId
        },
        { body: this.content.trim() }
      )
      const arr = []
      arr.push(params)
      const { code } = await sendmail(arr)
      console.log(code)
      this.faceShow = false
      this.content = ''
      this.$emit('refresh')
    },
    getfocus(val) {
      this.$refs.input.focus()
      this.content = val
    },
    setDate(n) {
      var day3 = new Date()
      day3.setTime(day3.getTime() + n * 24 * 60 * 60 * 1000)
      var s3 =
        day3.getFullYear() +
        '-' +
        (day3.getMonth() + 1) +
        '-' +
        day3.getDate() +
        ' ' +
        day3.getHours() +
        ':' +
        day3.getMinutes() +
        ':' +
        day3.getSeconds()
      return s3
    },
    async cancelIsWarn(id, status, time) {
      const { code, msg } = await batchUpdateMessageWarnById(id, status, time)
      code === 0 && this.$message.success(msg)
      this.$emit('refresh')
    },
    getRowStyle({ row }) {
      const stylejson = {}
      if (row.isRead === 1) {
        stylejson.color = '#999'
        return stylejson
      } else {
        return ''
      }
    },
    getBrow(index) {
      for (const i in this.faceList) {
        if (index + '' === i) {
          this.getBrowString = this.faceList[index]
          this.selectEmoji(this.getBrowString)
        }
      }
    },
    faceContent() {
      this.faceShow = !this.faceShow
      if (this.faceShow === true) {
        for (const i in appData) {
          this.faceList.push(appData[i].char)
        }
      } else {
        this.faceList = []
      }
    },
    Markasreplied(status) {
      this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        const { code } = await batchAmazonUpdateMessageStatus(
          this.basicInfo.id,
          status
        )
        code === 0
          ? this.disabledFlag
            ? (this.basicInfo.messageStatus = 5)
            : (this.basicInfo.messageStatus = 1)
          : ''
        this.$emit('refresh')
        code === 0
          ? this.$message.success(this.$t('title.Theoperation')) &&
            this.handlenextMessage(1)
          : this.$message.error(this.$t('title.operationfailed'))
      })
    },
    async _getAmazonPagedList(data) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { account, site, bu, workStatus } = JSON.parse(this.$route.query.form)
      this.nextForm = { account, site, bu }
      if (this.$route.query.id) {
        const id = this.$route.query.id
        const { datas, code } = await getAmazonPagedList({ size: 20, current: 1, total: 0, workStatus: workStatus, platform: 'SHOPIFY', messageType: 1, id: id })
        this.customerMessageList = []
        this.basicInfo = datas.records[0]
        this.workName = this.basicInfo.otherWorkingName ? this.basicInfo.otherWorkingName : ''
        // if (this.basicInfo.messageMarkLabelList == undefined || (this.basicInfo.messageMarkLabelList && this.basicInfo.messageMarkLabelList.length == 0)) {
        //   this.basicInfo.messageMarkLabelList = [{ markLabel: [] }]
        // }
        const { ebayMessageInfoList, orderList } = datas.records[0]
        this.OrderInfo = orderList
        console.log(this.basicInfo.id)
        this._getLogByMessageId({ messageId: this.basicInfo.id })
        // this.getNotice()
        this.customerMessageList = ebayMessageInfoList
        const recipient = this.customerMessageList[0].recipientId
        // console.log(this.customerMessageList[0].recipientId)
        for (var i = 0; i < this.customerMessageList.length; i++) {
          this.customerMessageList[i].senderId === recipient
            ? (this.customerMessageList[i].type = 2)
            : (this.customerMessageList[i].type = 1)
        }
        // console.log(this.customerMessageList)
        const { body } = this.customerMessageList.find((item) => item.messageStatus === 3) || []
        // console.log(body);
        body ? this.content = body.replace(/<br\>/g, '\r\n') : ''
        this.dialogVisible = true
        this.$nextTick(() => {
          this.scrollToBottom()
        })
        this.basicInfo.isRead === 0 ? this.markAsReaded(1) : ''
        // this.basicInfo.messageMarkLabelList[0].markLabel = ''
        if (code === 0) {
          loading.close()
          this._updateMessageServiceIsWorkingById({ id: this.basicInfo.id, isWorking: 1 })
          if (this.workName) {
            if (this.workName !== '') {
              this.$confirm(this.$t('title.alreadyexists') + this.workName + this.$t('title.continueeply'), {
                confirmButtonText: this.$t('title.nextMessage'),
                cancelButtonText: this.$t('title.Continuetoreply')
              }).then(() => {
                this.handlenextMessage()
              })
            }
          }
        }
      }
    },
    async _updateMessageServiceIsWorkingById(params) {
      const { code } = await updateMessageServiceIsWorkingById(params)
      if (code === 0) {
        this.$message.success(code)
      }
    },
    async markAsReaded(readed) {
      const { id } = this.basicInfo
      const { status } = await updateAmazonMessageReadById(id, readed)
      status === 200 ? this.$emit('refresh') : ''
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.singleHeight.scrollTop = this.$refs.singleHeight.scrollHeight
      })
    },
    async _getLogByMessageId(id) {
      const { datas } = await getLogByMessageId(id)
      this.noteLists = datas || []
      console.log(this.noteLists)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      console.log(this.multipleSelection)
      const ids = deepClone(val)
      this.ids = ids.map((item) => item.id)
      this.$emit('getthreadList', this.ids)
      this.$emit('getMul', this.multipleSelection)
    },
    keyDown(e) {
      console.log(e)
      const type = 1
      e = e || window.event
      e.ctrlKey && e.keyCode === 13 ? this.sendMsg(type) : ''
    },
    // 发送消息
    // async sendMsg(type, obj) {
    //   const json = this.$refs.editor.getEditorJSON()
    //   const { text, imgArr } = this.getEditorContent(json)
    //   console.log(imgArr)
    //   try {
    //     if (type === 1) {
    //       this.sendLoading = true
    //       // if (text === '') return this.$message.error('请输入内容')
    //       if (text === '' && !imgArr) {
    //         return this.$message.error('请输入内容')
    //       } else if (imgArr) {
    //         // const obj = { content: text, type: 2 }
    //       // text ? this.customerMessageList.push(obj) : ''
    //       // this.$set(obj, 'body', text.replace(/(\r\n)|(\n)/g, '<br>'))
    //       // this.$refs.editor.clearEditor()
    //       // this.scrollToBottom()
    //         const { orderCoder, ebayMessageInfoList, messageId, id } = this.basicInfo
    //         let From = ''
    //         // 买家邮件 emailType：1
    //         process.env.NODE_ENV !== 'production' ? From = 'youshiqi17@gmail.com' : From = ebayMessageInfoList[0].senderId
    //         const messageMediaVOList = []
    //         imgArr.map(e => {
    //           messageMediaVOList.push({
    //             mediaURL: e,
    //             mediaName: e.replace(/(.*\/)*([^.]+).*/ig, '$2')
    //           })
    //         })
    //         const form = JSON.parse(this.$route.query.form)
    //         const params = Object.assign(
    //           {},
    //           { operatorName: this.$store.getters.name,
    //             OperatorId: this.$store.getters.userId,
    //             orderId: orderCoder,
    //             status: 1,
    //             subject: ebayMessageInfoList[0].subject,
    //             threadId: messageId,
    //             site: form.site.includes('') ? '' : form.site.toString(),
    //             messageId: id, messageMediaVOList },
    //           { emailType: this.disabledFlag ? 0 : 1 },
    //           { from: ebayMessageInfoList[0].recipientId },
    //           { recipient: From },
    //           { message: text }
    //         )
    //         // console.log(params)
    //         const res = await sendmail(params)
    //         res.status === 200
    //           ? this.$message.success('发送成功')
    //           : this.$message.error('发送失败')
    //         if (res.status === 200) {
    //         // 回显渲染
    //           this.customerMessageList.push({
    //             mediaList: messageMediaVOList,
    //             body: text,
    //             type: 2
    //           })
    //           this.scrollToBottom()
    //           this.$refs.editor.clearEditor()
    //           this.richText = ''
    //         }
    //         this.faceShow = false
    //         this.sendLoading = false
    //         this.$emit('refresh')
    //         this.checked === true ? this.handlenextMessage(1) : ''
    //       }
    //     }
    //   } finally {
    //     this.sendLoading = false
    //   }
    // },
    // 发送消息
    async sendMsg(type, obj) {
      const form = JSON.parse(this.$route.query.form)
      try {
        if (type === 1) {
          this.sendLoading = true
          var text = this.content.replace(/<[^>]+>/g, '').trim()
          if (text === '') return this.$message.error(this.$t('tips.enterContent'))
          const obj = { content: text, type: 2 }
          text ? this.customerMessageList.push(obj) : ''
          this.$set(obj, 'body', text.replace(/(\r\n)|(\n)/g, '<br>'))
          this.content = ''
          this.scrollToBottom()
          const { orderCoder, ebayMessageInfoList, messageId, id } = this.basicInfo
          let From = ''
          // 买家邮件 emailType：1
          process.env.NODE_ENV !== 'production' ? From = 'youshiqi17@gmail.com' : From = ebayMessageInfoList[0].senderId
          console.log(From)
          const params = Object.assign(
            {},
            { operatorName: this.$store.getters.name,
              OperatorId: this.$store.getters.userId,
              orderId: orderCoder,
              status: 1,
              subject: ebayMessageInfoList[0].subject,
              threadId: messageId,
              site: form.site.includes('') ? '' : form.site.toString(),
              messageId: id },
            { emailType: this.disabledFlag ? 0 : 1 },
            { from: ebayMessageInfoList[0].recipientId },
            { recipient: From },
            { message: text.replace(/(\r\n)|(\n)/g, '<br>') }
          )
          // console.log(params)
          const res = await sendmail(params)
          res.status === 200
            ? this.$message.success(this.$t('tips.Sent'))
            : this.$message.error(this.$t('tips.Failedsend'))
          this.faceShow = false
          this.sendLoading = false
          this.$emit('refresh')
          this.checked === true ? this.handlenextMessage(1) : ''
        } else {
          const res = await sendmail(obj)
          if (res.status === 200) {
            this.$message.success(this.$t('tips.Sent'))
            this.customerMessageList.push({
              mediaList: obj.messageMediaVOList
            })
            // [{
            //   mediaList:[{
            //     mediaURL: xxxxx,
            //     mediaName: xxx
            //   }]
            // }]
            this.$nextTick(() => {
              this.scrollToBottom()
            })
          } else {
            this.$message.error(this.$t('tips.Failedsend'))
          }
          this.faceShow = false
          this.sendLoading = false
          this.$emit('refresh')
          this.checked === true ? this.handlenextMessage(1) : ''
        }
      } finally {
        this.sendLoading = false
      }
    },
    // 自动下一封
    async handlenextMessage(data) {
      this._updateMessageServiceIsWorkingById({ id: this.basicInfo.id, isWorking: 0 })
      if (this.content !== '') {
        var text = this.content.replace(/<[^>]+>/g, '').trim()
        if (text === '') return this.$message.error(this.$t('tips.enterContent'))
        const { orderCoder, ebayMessageInfoList, messageId, id } = this.basicInfo
        const form = JSON.parse(this.$route.query.form)
        let From = ''
        // 买家邮件 emailType：1
        process.env.NODE_ENV !== 'production' ? From = 'youshiqi17@gmail.com' : From = ebayMessageInfoList[0].senderId
        console.log(From)
        const params = Object.assign(
          {},
          { operatorName: this.$store.getters.name,
            OperatorId: this.$store.getters.userId,
            orderId: orderCoder,
            status: 3,
            subject: ebayMessageInfoList[0].subject,
            threadId: messageId,
            site: form.site.includes('') ? '' : form.site.toString(),
            messageId: id },
          { emailType: this.disabledFlag ? 0 : 1 },
          { from: ebayMessageInfoList[0].recipientId },
          { recipient: From },
          { message: text.replace(/(\r\n)|(\n)/g, '<br>') }
        )
        // console.log(params)
        const res = await sendmail(params)
        if (res === 200) {
          this.content = ''
        }
        console.log(res)
        // }
      } else if (this.content === '' && this.basicInfo.messageStatus === 3) {
        this.content = ''
        const { orderCoder, ebayMessageInfoList, messageId, id } = this.basicInfo
        const form = JSON.parse(this.$route.query.form)
        let From = ''
        // 买家邮件 emailType：1
        process.env.NODE_ENV !== 'production' ? From = 'youshiqi17@gmail.com' : From = ebayMessageInfoList[0].senderId
        console.log(From)
        const params = Object.assign(
          {},
          { operatorName: this.$store.getters.name,
            OperatorId: this.$store.getters.userId,
            orderId: orderCoder,
            status: 3,
            subject: ebayMessageInfoList[0].subject,
            threadId: messageId,
            site: form.site.includes('') ? '' : form.site.toString(),
            messageId: id },
          { emailType: this.disabledFlag ? 0 : 1 },
          { from: ebayMessageInfoList[0].recipientId },
          { recipient: From },
          { message: this.content.trim() }
        )
        // console.log(params)
        const res = await sendmail(params)
        if (res === 200) {
          this.content = ''
        }
      }
      const { account, site, bu } = this.nextForm
      const form = JSON.parse(this.$route.query.form)
      form.id = this.basicInfo.id
      form.account = account
      form.site = site
      form.bu = bu
      form.messageStatus = this.basicInfo.messageStatus
      this.form.id = this.basicInfo.id
      const { datas, code } = await getMessageInfoByCondition(form)
      // this.tableData = datas
      // let index = this.basicInfo.index
      // if (type === 1) {
      //   if (index === this.tableData.length - 1) { return this.$message.warning('这是当前分页最后一封邮件') }
      //   index++
      // } else {
      //   if (index === 0) { return this.$message.warning('这是当前分页第一封邮件') }
      //   index--
      // }
      // console.log(this.tableData.find((item) => item.index === index))
      // this.basicInfo = this.tableData.find((item) => item.index === index)
      // datas.messageMarkLabelList && datas.messageMarkLabelList.length === 0 ? datas.messageMarkLabelList.push[{ markLabel :'' }]:datas.messageMarkLabelList[0].markLabel = ''
      this.basicInfo = datas
      this.OrderInfo = datas.orderList
      this.customerMessageList = []
      this.customerMessageList = this.basicInfo.ebayMessageInfoList
      const recipient = this.customerMessageList[0].recipientId
      for (var i = 0; i < this.customerMessageList.length; i++) {
        this.customerMessageList[i].senderId === recipient
          ? (this.customerMessageList[i].type = 2)
          : (this.customerMessageList[i].type = 1)
      }
      const { body } = this.customerMessageList.find(item => item.messageStatus === 3) || []
      console.log(body)
      body ? this.content = body : ''
      this.basicInfo.isRead === 0 ? this.markAsReaded(1) : ''
      this._getLogByMessageId({ messageId: this.basicInfo.id })
      if (code === 0) {
        this._updateMessageServiceIsWorkingById({ id: this.basicInfo.id, isWorking: 1 })
        if (this.workName) {
          if (this.workName !== '') {
            this.$confirm(this.$t('title.alreadyexists') + this.workName + this.$t('title.continueeply'), {
              confirmButtonText: this.$t('title.nextMessage'),
              cancelButtonText: this.$t('title.Continuetoreply')
            }).then(() => {
              this.handlenextMessage()
            })
          }
        }
      }
      this.content = ''
    },
    // 关闭页面
    closeOrder() {
      this._updateMessageServiceIsWorkingById({ id: this.basicInfo.id, isWorking: 0 })
      this.$router.go(-1)
      // this.$router.push({
      //   path: '../Shopify-buyer-email',
      //   append: true
      // })
    },
    // 完结工单
    async allsendMsg() {
      const { code, msg } = await batchUpdateMessageWorkStatus({
        ids: this.basicInfo.id,
        status: 99
      })
      code === 0 && this.$message.success(msg)
      if (code === 0) {
        this.handlenextMessage(1)
      }
    },
    // 查看全部消息
    async allMeassge() {
      const { datas } = await getAllLogByMessageId({
        buyerId: this.basicInfo.messageSender,
        platform: 'SHOPIFY'
      })
      this.customerMessageList = datas
      // const recipient = this.customerMessageList[0].senderType
      for (var i = 0; i < this.customerMessageList.length; i++) {
        this.customerMessageList[i].senderType === 1
          ? (this.customerMessageList[i].type = 1)
          : (this.customerMessageList[i].type = 2)
      }
    },
    handleContent(val) {
      let str = val
      str = str.replace(/客户昵称/g, this.basicInfo.messageSender)
      str = str.replace(/账号名称/g, this.basicInfo.account)
      str = str.replace(/客服名称/g, this.$store.getters.name)
      this.selectEmoji(str)
    },
    // 插入昵称
    async nikeName(val) {
      let str = val
      if (val === '客户昵称') {
        str = val.replace('客户昵称', this.basicInfo.messageSender)
      } else if (val === '账号名称') {
        str = val.replace('账号名称', this.basicInfo.account)
      } else {
        str = val.replace('客服名称', this.$store.getters.name)
      }
      this.selectEmoji(str, 'nikeName')
    },
    selectEmoji(emoji, source) {
      var elInput = document.getElementById('textarea') // 根据id选择器选中对象
      var startPos = elInput.selectionStart // input a第0个字符到选中的字符
      var endPos = elInput.selectionEnd // 选中的字符到最后的字符

      if (startPos === undefined || endPos === undefined) return
      var txt = elInput.value
      // 将表情添加到选中的光标位置
      var result = txt.substring(0, startPos) + emoji + txt.substring(endPos)
      elInput.value = result// 赋值给input的value
      this.content = elInput.value
    },
    // 上传图片
    handleSuccess(res, file) {
      console.log(res, file)
      this.imageUrl = res.datas
      const { orderCoder, account, messageId, id, ebayMessageInfoList } = this.basicInfo
      let From = ''
      process.env.NODE_ENV !== 'production' ? From = 'youshiqi17@gmail.com' : From = ebayMessageInfoList[0].senderId
      const form = JSON.parse(this.$route.query.form)
      const obj = {
        messageMediaVOList: [{
          mediaURL: this.imageUrl,
          mediaName: file.name
        }],
        account,
        operatorName: this.$store.getters.name,
        OperatorId: this.$store.getters.userId,
        orderId: orderCoder,
        status: 1,
        subject: ebayMessageInfoList[0].subject,
        threadId: messageId,
        site: form.site.includes('') ? '' : form.site.toString(),
        messageId: id,
        senderId: ebayMessageInfoList[0].recipientId,
        senderType: 2,
        emailType: this.disabledFlag ? 0 : 1,
        from: ebayMessageInfoList[0].recipientId,
        recipient: From
      }
      console.log(obj)
      const type = 0
      this.sendMsg(type, obj)
      this.$refs.upload.clearFiles()
    },
    // 限制图片大小
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 3
      if (!isLt5M) {
        this.$message.error(this.$t('tips.SnallerThan'))
      }
      return isLt5M
    },
    handleClick() {
      console.log('click')
    }

  }
}
</script>

<style scoped lang="scss">
/deep/.el-badge__content.is-fixed {
  position: absolute;
  top: 8px;
  right: 5px;
  -webkit-transform: translateY(-50%) translateX(100%);
  transform: translateY(-50%) translateX(100%);
}
.browBox {
  width: 100%;
  height: 130px;
  background: #e6e6e6;
  position: absolute;
  top: 29px;
  overflow: scroll;
  z-index: 999;
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 3px;
    li {
      width: 7%;
      font-size: 20px;
      list-style: none;
      text-align: center;
    }
  }
}
.dialog {
  em {
    font-style: normal;
    margin-right: 40px;
  }
  .border {
    border-bottom: 1px solid #ccc;
  }
}
pre{
  white-space:pre-wrap;
  word-wrap:break-word;
  }
/deep/.cell {
  min-height: 40px !important;
  line-height: 40px !important;
  // position: relative !important;
  em {
    position: absolute;
    top: 0px;
    right: 10px;
    padding: 0 10px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: white;
    background-color: red;
    border-radius: 10px;
    font-style: normal;
  }
}
.wrap {
  width: 100%;
  border: 1px solid #ccc;
  ul {
    min-height: 40px;
    list-style: none;
    li {
      overflow: hidden;
      margin: 10px 0px 10px;
    }
    .left_word {
      div {
        float: left;
        background-color: rgb(255, 255, 255);
        padding: 5px 10px;
        max-width: 60%;
        overflow-x: auto !important;
        border: 1px solid #ccc;
        border-radius: 12px;
        font-size: 14px;
        color: #000;
        margin-left: 13px;
        position: relative;
        line-height: 24px;
        /deep/.el-image {
          border: none !important;
          width: 70px !important;
          height: 70px !important;
          margin-left: 0 !important;
          padding: 5px !important;
        }
      }
    }
    .Left_Word {
      span {
        float: left;
        background-color: rgb(255, 255, 255);
        padding: 5px 10px;
        max-width: 95%;
        overflow-x: auto !important;
        border: 1px solid #ccc;
        border-radius: 12px;
        font-size: 14px;
        color: #000;
        margin-left: 13px;
        position: relative;
        line-height: 24px;
      }
    }
    .right_word {
      img {
        width: 30px;
        height: 30px;
        float: right;
        margin-right: 20px;
      }
      span {
        float: right;
        background-color: #00cc00;
        padding: 5px 10px;
        max-width: 450px;
        border-radius: 12px;
        font-size: 14px;
        color: #fff;
        margin-right: 13px;
        position: relative;
        line-height: 24px;
      }
    }
  }
  .main {
    height: 360px;
    overflow: auto;
    .talk_list {
      padding: 0px !important;
    }
  }
  .footer {
    position: relative;
    margin: 10px 5px;
  }
}
/deep/ .el-textarea__inner {
  min-height: 85px !important;
  line-height: 1 !important;
}
.unread {
  color: #333;
}
.read {
  color: #999;
}
.app-container {
  padding: 10px;
}
.el-table {
  /deep/ th {
    padding: 0;
  }
}

/deep/.el-table thead .cell {
  padding-top: 10px;
  line-height: 30px;
}
/deep/.el-icon-circle-close:before {
  content: "\e78d";
  color: #fff !important;
}
.active {
  color: rgb(218, 218, 218);
}
.addWidth{
  widows: 100%;
}
</style>

